import React from "react";
import lucienneImg from "../assets/lucienne.jpg";

export default function Hero() {
  return (
    <div className="primary-background primary-padding">
      <div className="container" id="hero">
        <div className="flexbox-horiz">
          <div className="flexbox-vert">
            <h1 className="title-top primary-text">Lucienne Franco</h1>
            <h2 className="title-bottom secondary-text">
              Singer - Pianist - Teacher
            </h2>
            <p className="secondary-text">
              Lucienne Franco is a classically trained soprano and pianist in Southern California.
              Having graduated from Chapman University in Vocal Performance, she has been involved
              with musical theatre, opera, choral, and solo work since a young age. Presently, you
              can find her performing at the Jazz Kitchen in Downtown Disney, and at Cooper Ridge 
              Vineyard in Roseburg, Oregon this summer!
            </p>
          </div>
          <img src={lucienneImg} className="hero-image" alt="" />
        </div>
      </div>
    </div>
  );
}
